import React from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Button, Avatar } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import logo from './dayne_davis.jpg';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '32px',
    width: '32px',
  },
  toolbar: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  menuButton: {
    marginRight: '4px',
  },
  profileButton: {
    marginLeft: 'auto',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  onToggleDrawer(): void;
}

export const NavBar = (props: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar} disableGutters variant="dense">
          <IconButton
            onClick={props.onToggleDrawer}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          {/* <img src={clogo} /> */}
          <Typography variant="h6" color="inherit">
            ARR Analysis
          </Typography>
          <div className={classes.profileButton}>
            <div className={classes.rightSection}>
              <IconButton
                onClick={props.onToggleDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                onClick={props.onToggleDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <NotificationsIcon />
              </IconButton>
              <Avatar
                className={classes.avatar}
                alt={'Dayne'}
                // src={user.picture}
                src={logo}
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen as any}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
};
