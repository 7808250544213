import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import readXlsxFile from 'read-excel-file';
import XLSX from 'xlsx';
import express from 'express';
import { setConstantValue } from 'typescript';
import { parse } from 'papaparse';
import { NavBar } from './NavBar';
import SideDrawer from './SideDrawer';
import { Container, Grid, Paper } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { CalculationTables, getStartColumn } from './CalculationTables';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    flex: 1,
    overflowY: 'scroll',
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '100%',
    width: '100%',
  },
  gridItem: {
    padding: '16px',
  },
  paper: {
    padding: '16px',
  },
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#efefef',
  },
  outerGrid: {
    height: '100%',
    width: '100%',
  },
}));

type CsvRow = (number | string)[];
type Csv = CsvRow[];

interface State {
  drawerOpen: boolean;
  client: any;
  csv: Csv | null;
}

function App() {
  const [state, setState] = useState<State>({
    client: null,
    drawerOpen: false,
    csv: null,
  });

  const toggleDrawer = () => {
    setState((state) => ({
      ...state,
      drawerOpen: !state.drawerOpen,
    }));
  };

  function handleChange(files: any) {
    console.log('files', files);
    const file = files?.[0];

    if (file) {
      parse(file, {
        complete: (results) => {
          console.log('results', results);
          const data = results.data as Csv;
          const startColumn = getStartColumn(data);
          const csv = data
            .filter((row) => row[0])
            .map((row, rowIndex) =>
              row.map((col, colIndex) =>
                rowIndex > 0 && colIndex >= startColumn ? Number(col) : col
              )
            );

          if (csv) {
            setState((state) => ({
              ...state,
              csv,
            }));
          }
        },
      });
    }
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavBar onToggleDrawer={toggleDrawer} />
      <SideDrawer drawerOpen={state.drawerOpen} onToggleDrawer={toggleDrawer} />
      <Container maxWidth="xl" className={classes.appContainer}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Grid className={classes.outerGrid} container justify="center">
            <Grid item className={classes.gridItem} xs={9}>
              {!state.csv ? (
                <Paper className={classes.paper}>
                  <DropzoneArea
                    showAlerts={false}
                    showPreviewsInDropzone={false}
                    onChange={handleChange}
                  />
                </Paper>
              ) : (
                <CalculationTables csv={state.csv} />
              )}
            </Grid>
          </Grid>
        </React.Suspense>
      </Container>
    </div>
  );
}

export default App;
