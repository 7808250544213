import React, { FunctionComponent, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import readXlsxFile from 'read-excel-file';
import XLSX from 'xlsx';
import express from 'express';
import { setConstantValue } from 'typescript';
import { parse } from 'papaparse';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { isValid } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import classes from '*.module.css';
import { stringify } from 'node:querystring';

// const csv = [
//   [
//     'Customer Name',
//     'Industry',
//     '12/31/2018',
//     '3/31/2019',
//     '6/30/2019',
//     '9/30/2019',
//     '12/31/2019',
//     '3/31/2020',
//     '6/30/2020',
//     '9/30/2020',
//     '12/31/2020',
//   ],
//   ['Toyota', 'Auto', 100, 100, 150, 150, 150, 150, 150, 300, 300],
//   ['Nike', 'Retail', 200, 200, 200, 200, 200, 200, 200, 200, 200],
//   ['Salesforce', 'Tech', 10, 10, 5, 5, 5, 0, 0, 0, 0],
//   ['Snowflake', 'Tech', 50, 50, 50, 100, 150, 200, 250, 300, 300],
//   ['Tencent', 'Tech', 0, 0, 0, 0, 100, 100, 200, 300, 300],
//   ['Mcgraw-Hill', 'Education', 0, 0, 0, 100, 100, 100, 100, 0, 0],
//   ['Youtube', 'Tech', 50, 50, 75, 100, 100, 150, 300, 0, 0],
//   ['LinkedIn', 'Tech', 0, 0, 0, 0, 0, 0, 1000, 1000, 1250],
//   ['Colgate', 'Consumer Goods', 100, 100, 100, 75, 75, 75, 0, 0, 0],
//   ['J&J', 'Consumer Goods', 0, 0, 0, 0, 0, 500, 500, 500, 500],
//   ['Nissan', 'Auto', 0, 0, 0, 0, 100, 100, 100, 200, 200],
// ];

const useStyles = makeStyles((theme) => ({
  table: {
    margin: '16px',
  },
  tablePaper: {
    overflowX: 'scroll',
    padding: '16px',
  },
  selectFilter: {
    minWidth: '100px',
  },
  selectFilterControl: {
    flex: 1,
  },
  filters: {
    display: 'inline-flex',
    gap: '16px',
    width: '100%',
    justifyContent: 'center',
  },
}));

// const START_COL = 2;
const START_ROW = 1;

type CsvRow = (number | string)[];
type Csv = CsvRow[];
type FiltersSet = Set<string>;
type Filters = Record<string, FiltersSet>;
type FiltersSelections = Record<string, string>;
interface State {
  csv: Csv;
  filteredCsv: Csv;
  newRevenue: Csv;
  churn: Csv;
  upsell: Csv;
  downsell: Csv;
  rollForward: Csv;
  industry: string | null;
  cohort: string | null;
  filters: Filters;
  filtersSelections: FiltersSelections;
}

export function CalculationTables(props: { csv: Csv | null }) {
  const [state, setState] = useState<State>(() => ({
    csv: [],
    filteredCsv: [],
    newRevenue: [],
    churn: [],
    upsell: [],
    downsell: [],
    rollForward: [],
    industry: null,
    cohort: null,
    filters: {},
    filtersSelections: {},
  }));

  const { csv } = props;

  useEffect(() => {
    if (csv) {
      setState((state) => ({ ...state, csv, filteredCsv: csv }));
    }
  }, [csv]);

  useEffect(() => {
    const START_COL = getStartColumn(state.filteredCsv);
    const filters: Filters = {};

    state.filteredCsv.forEach((row, rowIndex) => {
      if (rowIndex > 0) {
        row.forEach((col, colIndex) => {
          if (colIndex < START_COL) {
            const label = state.filteredCsv[0][colIndex].toString();
            const value = col;
            filters[label] = filters[label] ?? new Set<string>();
            filters[label].add(value.toString());
          }
        });
      }
    });

    setState((state) => ({
      ...state,
      filters,
    }));
  }, [state.filteredCsv]);

  useEffect(() => {
    const filteredCsv = state.csv.filter((row, rowIndex) => {
      if (rowIndex > 0) {
        return row.every((col, colIndex) => {
          const key = state.csv[0][colIndex];
          return (
            !state.filtersSelections[key] ||
            col.toString() === state.filtersSelections[key]
          );
        });
      } else {
        return true;
      }
    });

    setState((state) => ({ ...state, filteredCsv }));
  }, [state.filtersSelections, state.csv]);

  useEffect(() => {
    const filteredCsv = state.filteredCsv;

    if (filteredCsv.length > 0) {
      setState((state) => ({
        ...state,
        newRevenue: newRevenue(filteredCsv),
        churn: churn(filteredCsv),
        upsell: upsell(filteredCsv),
        downsell: downsell(filteredCsv),
        rollForward: rollForwardWithBeginning(filteredCsv),
      }));
    }
  }, [state.filteredCsv]);

  return (
    <div className="App">
      {state.filteredCsv.length > 0 && (
        <>
          {/* <CsvTable title="New Revenue" csv={withTotal(state.newRevenue)} />
          <br />
          <br />
          <CsvTable title="Churn" csv={withTotal(state.churn)} />
          <br />
          <br />
          <CsvTable title="Upsell" csv={withTotal(state.upsell)} />
          <br />
          <br />
          <CsvTable title="Downsell" csv={withTotal(state.downsell)} />
          <br />
          <br /> */}
          <CsvTable
            title="Rollforward Analysis"
            csv={[
              ...endingARR(state.filteredCsv),
              [],
              quarterOverQuarterGrowth(
                endingARR(state.filteredCsv)[
                  endingARR(state.filteredCsv).length - 1
                ]
              ),
              yearOverYearGrowth(
                endingARR(state.filteredCsv)[
                  endingARR(state.filteredCsv).length - 1
                ]
              ),
              grossRetention(state.filteredCsv),
              netRetention(state.filteredCsv),
            ]}
          />
          <Filters
            filters={state.filters}
            filtersSelections={state.filtersSelections}
            selectFilter={(key: string, value: string) =>
              setState((state) => ({
                ...state,
                filtersSelections: { ...state.filtersSelections, [key]: value },
              }))
            }
          />
          <CsvTable title="Data" csv={state.filteredCsv} />
        </>
      )}
    </div>
  );
}

interface FiltersProps {
  filters: Filters;
  filtersSelections: FiltersSelections;
  selectFilter(key: string, value: string): void;
}

const Filters = (props: FiltersProps) => {
  const classes = useStyles();
  const filtersSets = Object.entries(props.filters);

  return (
    <>
      <h3>Filter Data</h3>
      <PaddedPaper>
        <div className={classes.filters}>
          {filtersSets.map(([key, values]) => (
            <FormControl
              className={classes.selectFilterControl}
              key={key}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {key}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.selectFilter}
                value={props.filtersSelections[key] ?? null}
                onChange={(item) => {
                  console.log('value', item.target.value);
                  props.selectFilter(key, item.target.value as string);
                }}
                label={key}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Array.from(values).map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </div>
      </PaddedPaper>
    </>
  );
};

const PaddedPaper: FunctionComponent<{}> = (props) => {
  const classes = useStyles();
  return <Paper className={classes.tablePaper}>{props.children}</Paper>;
};

function CsvTable(props: { csv: Csv; title: string }) {
  return (
    <>
      <h3>{props.title}</h3>
      {props.csv.length > 0 && (
        <TableContainer component={PaddedPaper}>
          <Table padding="checkbox" aria-label="simple table">
            <TableHead>
              <TableRow>
                {props.csv[0].map((val, idx) => (
                  <TableCell align={idx < 2 ? 'left' : 'right'}>
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.csv
                .filter((val, index) => index > 0)
                .map((row, rIndex) => (
                  <TableRow key={rIndex}>
                    {row.map((val, idx) => (
                      <TableCell align={idx < 2 ? 'left' : 'right'}>
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {/* {props.csv.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
              </TableRow>
            ))} */}
            </TableBody>
          </Table>
        </TableContainer>
        // <table>
        //   <thead>
        //     {props.csv[0].map((val) => (
        //       <th>{val}</th>
        //     ))}
        //   </thead>
        //   <tbody>
        //     {props.csv
        //       .filter((val, index) => index > 0)
        //       .map((row) => (
        //         <tr>
        //           {row.map((val) => (
        //             <td>{val}</td>
        //           ))}
        //         </tr>
        //       ))}
        //   </tbody>
        // </table>
      )}
    </>
  );
}

function csvData(csv: Csv) {
  const START_COL = getStartColumn(csv);
  return csv.map(function (row) {
    return row.slice(START_COL);
  });
}

function csvDataWithLabels(data: Csv, csv: Csv) {
  const START_COL = getStartColumn(csv);
  return data.map(function (row, index) {
    return [...csv[index].slice(0, START_COL), ...row];
  });
}

function withTotal(csv: Csv, label?: string) {
  return [...csv, total(csv, label)];
}

function endingARR(csv: Csv) {
  const START_COL = getStartColumn(csv);
  const rollForward = rollForwardWithBeginning(csv);
  const totalARR = withTotal(rollForward, 'Ending ARR');
  const start = total(csv)[START_COL];
  totalARR[totalARR.length - 1][1] = start;
  return totalARR;
}

function alterCsv(csv: Csv, transform: (prev: number, curr: number) => number) {
  const START_COL = getStartColumn(csv);
  const data = csvData(csv);
  const transformedData = data.map((row, index) => {
    // if (index < START_ROW) {
    //   return row;
    // }
    return alterRow(row, 0, transform);
  });
  return csvDataWithLabels(transformedData, csv);
}

function formatPercentage(x: number) {
  return `${(x * 100).toFixed(2)}%`;
}

function yearOverYearGrowth(row: (number | string)[]) {
  return row.map((col, index) => {
    if (index === 0) {
      return 'YoY Growth';
    } else if (index < 5) {
      return '';
    } else {
      const prev = row[index - 4] as number;
      const curr = row[index] as number;

      if (!prev || !curr) {
        return '';
      }

      return (curr || curr === 0) && (prev || prev === 0)
        ? formatPercentage(curr / prev - 1)
        : '';
    }
  });
}

function quarterOverQuarterGrowth(row: (number | string)[]) {
  return row.map((col, index) => {
    if (index === 0) {
      return 'QoQ Growth';
    } else if (index === 1) {
      return '';
    } else {
      const prev = row[index - 1] as number;
      const curr = row[index] as number;

      // TODO How to handle prev 0 and curr 0
      const v =
        (curr || curr === 0) && (prev || prev >= 1)
          ? formatPercentage(curr / prev - 1)
          : prev === 0 || curr === 0
          ? formatPercentage(0)
          : '';

      console.log('qoq', curr, prev, v);
      return v;
    }
  });
}

export function getStartColumn(csv: Csv) {
  const startColumn = csv[0]
    ? csv[0].findIndex((val) => isValid(new Date(val)))
    : 2;
  return startColumn;
}

function grossRetention(csv: Csv) {
  const churnTotal: CsvRow = total(churn(csv));
  const beginningArrResult: CsvRow = beginningArr(csv);
  return beginningArrResult.map((col, index) => {
    if (index === 0) {
      return 'Gross Retention';
    } else {
      const itemA = churnTotal[index + 1] as number;
      const itemB = beginningArrResult[index] as number;

      return (itemA || itemA === 0) && (itemB || itemB === 0)
        ? formatPercentage(itemA / itemB + 1)
        : '';
    }
  });
}

function netRetention(csv: Csv) {
  const rollForwardResult = rollForwardWithBeginning(csv);
  const beginningArrRowIndex = 1;
  const upsellRowIndex = 3;
  const downsellRowIndex = 4;
  const churnRowIndex = 5;

  return rollForwardResult[0].map((row, index) => {
    if (index === 0) {
      return 'Net Retention';
    } else {
      const beginning = rollForwardResult[beginningArrRowIndex][
        index
      ] as number;
      const up = rollForwardResult[upsellRowIndex][index] as number;
      const down = rollForwardResult[downsellRowIndex][index] as number;
      const churn = rollForwardResult[churnRowIndex][index] as number;

      return (up || up === 0) && (down || down === 0) && (churn || churn === 0)
        ? formatPercentage((up + down + churn) / beginning + 1)
        : '';
    }
  });
}

function mergeRows(
  a: (number | string)[],
  b: (number | string)[],
  startColumn: number,
  transform: (prev: number, curr: number) => number
) {
  return a.map((col, index) => {
    if (index < startColumn) {
      return col;
    } else {
      const itemA = a[index] as number;
      const itemB = b[index] as number;

      return transform(itemA, itemB);
    }
  });
}

function alterRow(
  row: (number | string)[],
  startColumn: number,
  transform: (prev: number, curr: number) => number
) {
  return row.map((col, index) => {
    if (index < startColumn) {
      return col;
    } else {
      const prev = row[index - 1] as number;
      const curr = row[index] as number;

      return transform(prev, curr);
    }
  });
}

function total(csv: Csv, label?: string) {
  const START_COL = getStartColumn(csv);
  let firstRow = true;

  if (!csv[0]) {
    return [];
  }

  return csv.reduce((prev, row) => {
    if (firstRow) {
      firstRow = false;
      return prev;
    }
    return prev.map((col, index) => {
      if (index === 0) {
        return label ?? 'Total';
      } else if (index < START_COL) {
        return '';
      } else {
        return (Number(col) as number) + (Number(row[index]) as number);
      }
    });
  }, new Array(csv[0].length).fill(0));
}

function rollForward(csv: Csv) {
  const START_COL = getStartColumn(csv);
  const totalARR = total(newRevenue(csv));
  const totalUpsellARR = total(upsell(csv));
  const totalDownsellARR = total(downsell(csv));
  const totalChurn = total(churn(csv));

  return [
    ['', ...csv[0].slice(START_COL)],
    ['(+) New ARR', '', ...totalARR.slice(START_COL + 1, totalARR.length)],
    [
      '(+) Upsell ARR',
      '',
      ...totalUpsellARR.slice(START_COL + 1, totalUpsellARR.length),
    ],
    [
      '(-) Downsell ARR',
      '',
      ...totalDownsellARR.slice(START_COL + 1, totalDownsellARR.length),
    ],
    ['(-) Churn', '', ...totalChurn.slice(START_COL + 1, totalChurn.length)],
  ] as Csv;
}

function beginningArr(csv: Csv) {
  const START_COL = getStartColumn(csv);
  const rollForwardCsv = rollForward(csv);
  const startArr = total(csv)[START_COL];
  const totalRoll = total(rollForwardCsv);
  const beginning: any = [];

  totalRoll.forEach((val, index) => {
    if (index === 0) {
      beginning.push('Beginning ARR');
    } else if (index === 1) {
      beginning.push('');
    } else if (index === 2) {
      beginning.push(startArr);
    } else {
      beginning.push((beginning[index - 1] || 0) + totalRoll[index - 1]);
    }
  });

  return beginning;
}

function rollForwardWithBeginning(csv: Csv) {
  const START_COL = getStartColumn(csv);
  const rollForwardCsv = rollForward(csv);
  const startArr = total(csv)[START_COL];
  const totalRoll = total(rollForwardCsv);
  const beginning: any = [];

  totalRoll.forEach((val, index) => {
    if (index === 0) {
      beginning.push('Beginning ARR');
    } else if (index === 1) {
      beginning.push('');
    } else if (index === 2) {
      beginning.push(startArr);
    } else {
      beginning.push((beginning[index - 1] || 0) + totalRoll[index - 1]);
    }
  });

  rollForwardCsv.splice(1, 0, beginning);
  return rollForwardCsv;
}

function newRevenue(csv: Csv) {
  const transform = (prev: number, curr: number) => {
    return curr > prev && prev === 0 ? curr - prev : 0;
  };

  return alterCsv(csv, transform);
}

function churn(csv: Csv) {
  const transform = (prev: number, curr: number) => {
    return curr < prev && curr === 0 ? curr - prev : 0;
  };
  return alterCsv(csv, transform);
}

function upsell(csv: Csv) {
  const transform = (prev: number, curr: number) => {
    return prev > 0 && curr > prev ? curr - prev : 0;
  };
  return alterCsv(csv, transform);
}

function downsell(csv: Csv) {
  const transform = (prev: number, curr: number) => {
    return curr > 0 && curr < prev ? curr - prev : 0;
  };
  return alterCsv(csv, transform);
}
