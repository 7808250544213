import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PowerIcon from '@material-ui/icons/Power';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fullList: {
    width: 'auto',
  },
  list: {
    width: 250,
  },
}));

interface Props {
  drawerOpen: boolean;
  onToggleDrawer(): void;
}

const SideDrawer = (props: Props) => {
  const classes = useStyles();

  return (
    <Drawer open={props.drawerOpen} onClose={props.onToggleDrawer}>
      <div
        className={classes.list}
        role="presentation"
        onClick={props.onToggleDrawer}
        onKeyDown={props.onToggleDrawer}
      >
        <List>
          <ListItem
            button
            key="KPI Dashboards"
            onClick={() => console.log('/dashboards')}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboards" />
          </ListItem>
          <ListItem
            button
            key="ARR Analysis"
            onClick={() => console.log('/charts')}
          >
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="ARR Analysis" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
